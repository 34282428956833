import config from "./config";
import _ from 'lodash';
export const SERVERIP = config.API_URL;
export const NotificationHUB = config.API_URL + "/chatHub";
export const ProductApi = "/api/product/";
export const ProductSerialApi = "/api/productserial/";
export const ManufacturerApi = "/api/manufacturer/";
export const AdminApi = "/api/admin/";
export const AccountApi = "/api/profile/";
export const ProfileController = SERVERIP + AccountApi
export const RegisterApi = SERVERIP + "/api/account/register";
export const ForgotApi = SERVERIP + "/api/account/forgot";
export const ResetApi = SERVERIP + "/api/account/reset";
export const CheckUsername = SERVERIP +"/api/account/AccountExist?search="
export const UserChangePassword = SERVERIP + "/api/profile/change_password";
export const ActivateUserProfile = SERVERIP + "/api/profile/activate";
export const ActivateApi = SERVERIP + "/api/account/activate";
export const ProfileApi = SERVERIP + AccountApi + "profile";
export const DeleteBatchApi = SERVERIP + AdminApi + "DeleteBatch";
export const RestoreBatchApi = SERVERIP + AdminApi + "RestoreBatch";
export const ChangeMemberPasswordAdmin = SERVERIP + AccountApi + "reset_account";
export const ProfilePhotoApi = SERVERIP + AccountApi + "profile_photo";
export const ProfilePhotoStaffApi = SERVERIP + AccountApi + "profile_photo_staff";
export const DownloadFailedSerialCsv = SERVERIP + ProductApi + "download_failed";
export const DownloadNotFoundSerialCsv = SERVERIP + AdminApi + "notfound";
export const DownloadInstallersCsv = SERVERIP + AdminApi + "installers";
export const DownloadParallelSerialCsv = SERVERIP + AdminApi + "parallel";
export const DownloadLogs = SERVERIP + AdminApi + "download_log?batchid=";


export const DownloadReport = SERVERIP + ProductApi + "downloadHistoricalReport?batchId=";
export const DownloadOriginal = SERVERIP + ProductApi + "downloadHistoricalOriginalFile?batchId=";
export const DownloadLedgerReport = SERVERIP + ProductApi + "downloadLedgerReport?batchId=";
export const InstalledProductsWattageReport = SERVERIP + ManufacturerApi + "installedProductsWattageReport?timespan=";


export const RefreshInstallers = ProfileController + "cecinst";
export const RefreshProducts = ProfileController + "ceclist";
export const RefreshManufacturerProducts = ProfileController + "sync_all";
export const RefreshReferenceData = SERVERIP+ AdminApi + "RefreshReferenceData";
export const RefreshCerts = config.VSP_URL+  "/api/endpoint/reload";
export const FetchCertInfo = config.VSP_URL + "/api/endpoint/thumbprint?certname=";
export const FetchAnalytics = SERVERIP + AdminApi + "InstallationAnalysisReport?";
export const ResolveModeConstant = "resolve";
export const StaffApi = SERVERIP + "/api/staff/staff";
export const DistributorStaffApi = SERVERIP + "/api/staff/distributorstaff";
export const EditStaffApi = SERVERIP + "/api/staff/editstaff";
export const EditDistributorStaffApi = SERVERIP + "/api/staff/editdistributorstaff";
export const DeleteStaffApi = SERVERIP + "/api/staff/delete";
export const DeleteDistributorStaffApi = SERVERIP + "/api/staff/delete_dstaff";
export const ProfilePath = SERVERIP + "/api/Profile/UserLogo/";
export const LogoPath = SERVERIP + "/api/Brand/Logo/";
export const SerialImagePath = SERVERIP + ProductSerialApi + "image?name=";
export const ProductLineImagePath = SERVERIP + "/api/ProductLine/Logo/";
export const AddProductLineApi = SERVERIP + "/api/productline/addproductline";
export const UpdateProductLineApi = SERVERIP + "/api/productline/updatedproductline";
export const AssignProductLineApi = SERVERIP + "/api/productline/assignproductline";
export const AddSupplier = SERVERIP + "/api/account/register_distributor";
export const UnAssignProductlineApi = SERVERIP + "/api/productline/unassignproductline";
export const DeleteProductLineApi = SERVERIP + "/api/productline/delete";
export const LogoApi = SERVERIP + AccountApi + "logo";
export const CompanyApi = SERVERIP + AccountApi + "company";
export const ManufacturerAvailable_EndPoint = SERVERIP + AccountApi + "manufactureravailable?search=";
export const ManufacturerValid_EndPoint = SERVERIP + AccountApi + "isvalid?search=";
export const LINKS_PER_PAGE = 100;
export const PAGINATION_TYPE = { PAGE_ITEM_LABEL: 'label', PAGE_ITEM_PREVIOUS: 'previous', PAGE_ITEM_NEXT: 'next', PAGE_ITEM_PAGE: 'page', PAGE_ITEM_PAGE_DROPDOWN: 'page-dropdown', PAGE_ITEM_FIRST: 'first', PAGE_ITEM_LAST: 'last' }
export const PAGINATION_SETTING = { MAX_PAGES: 20 }
export const SERIAL_TYPE = { ALL: 0, APPROVED: 1, PENDING: 2, FAILED: 3,PARALLEL: 4 }
export const SHEET_TRIM_LENGTH = 40;
export const UploadFrequencyData = [
    { code: "1", name: "Daily" },
    { code: "2", name: "Weekly" },
    { code: "3", name: "Fortnightly" },
    { code: "4", name: "Monthly" },
    { code: "5", name: "Quarterly" },
    { code: "6", name: "Annually" }
]
//function bin_to_dec(bstr) {
//    return parseInt((bstr + '')
//        .replace(/[^01]/gi, ''), 2);
//}
export const UserIdExceptions =
    process.env.NODE_ENV === 'production' ?
        ['71ae3f31-0fd2-476e-8d97-a4386dd69202', '7f7a817f-77f9-4d60-b2e4-318da6116723', '4f43d79d-22f7-4934-836a-0c6ecd26122d', '5edfe438-a012-41a7-94ae-b979b3ffd5f0']
        : ['6a4fa1e2-7ca6-43c7-9363-9d04043e2783'];

export const SAdminList =
    process.env.NODE_ENV === 'production' ?
        ['6e62f1bc-f434-472f-aea0-9fe42f76f687', '5edfe438-a012-41a7-94ae-b979b3ffd5f0', '969e19fd-b9fb-4832-b215-30d851bfc32b','89e81bba-365b-4923-8519-c096b7935e85']
        : ['6e62f1bc-f434-472f-aea0-9fe42f76f687', '5edfe438-a012-41a7-94ae-b979b3ffd5f0', '969e19fd-b9fb-4832-b215-30d851bfc32b', '89e81bba-365b-4923-8519-c096b7935e85'];

export const Permissions = [
    { Id: 1, name: "Read", checked: false },
    { Id: 2, name: "Upload", checked: false },
    { Id: 3, name: "Approve Supplier", checked: false },
    { Id: 4, name: "Download", checked: false },
    { Id: 5, name: "Manage Profile", checked: false },
    { Id: 6, name: "Manage Staff", checked: false },

]

export const handleErrors = (response) => {
    if (!response.ok) {
        throw response; 
    } return response.json();
}
export const handleHttpException = (response) => {

    if (response.status === 400) {
        return response.json().then(errMsg => {
            return errMsg.message;
        });
    }
    else {
        new Promise(response.statusText);
    }



};

export const RandomText = (length) => {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

export const ParseDateFromString = (dateString) => {
    var parts = dateString.split('-');
    var yearOnly = parts[2].split(' ')[0];
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    return new Date(yearOnly, parts[1] - 1, parts[0]);
}
export const ShowNotUploadingAlert = (date1,days=300) => {
    if (_.isNil(date1)) {
        return true;
    }
    const date = ParseDateFromString(date1);
    return DateDiff(date) > days;
}

export const DateDiff = (date1, date2=new Date()) => {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    
}

export const Role_Admin = 'superadmin';
export const Role_MANUFACTURER = 'madmin';
export const Role_DISTRIBUTOR = 'mdistributor';
export const Role_STAFF = 'mstaff';
export const Role_DISTRIBUTOR_STAFF = 'distributor_staff';
export const MANUFACTURER_ID_CanadianSolarInc = 29;
export const MANUFACTURER_ID_SunPowerCorporation = 24;
