import React, { createContext, useContext, useState, useCallback } from 'react';
import { handleDeleteBatch, handleRestoreBatch, handleDownloadOriginal, handleDownloadLedgerHistory, handleDownloadReport } from '../../Helper';
import UserProfile from '../auth/UserProfile';
import { LINKS_PER_PAGE, Role_Admin } from '../../constants';
import classNames from 'classnames';
const UploadHistoryContext = createContext();

export const UploadHistoryProvider = ({ children, manufacturerId: mid,classes ,refetch,onDone }) => {
    const [dialogContent, setDialogContent] = useState({
        title: '',
        body: '',
        actionType: 0,
        actionButtonTitle: 'Confirm',
        uploadId: 0,
        message: null
    });
    const [dialogProgress, setDialogProgress] = useState(false);
    const [isDownloadOriginalInProgress, setDownloadOriginalInProgress] = useState(false);
    const [isDownloadHistoricInProgress, setDownloadHistoricInProgress] = useState(false);
    const [isDownloadLedgerInProgress, setDownloadLedgerInProgress] = useState(false);
    const [hasError, setError] = useState(false);
    const [activeUploadId, setActiveUploadId] = useState(null);
   
    const [dialogState, setDialogState] = useState(false);

   
    const isAdmin = (Role_Admin === UserProfile.getRole());

    

    const getManufacturerId = useCallback(() => (
        Role_Admin === UserProfile.getRole() ? mid : UserProfile.getManufacturerId()
    ), [mid]);

    const downloadOriginal = useCallback((batchId, fileName,manufacturerId, uploadId) => {
        setDownloadOriginalInProgress(true);
        setActiveUploadId(uploadId);
        const extension = "." + fileName.split('.').pop();
        const contentType = extension.toLowerCase() === ".csv" ? "text/csv" : "application/excel";
        handleDownloadOriginal(batchId, manufacturerId, fileName, extension, contentType)
            .then(() => resetDownloadState(false))
            .catch(() => resetDownloadState(false));
    }, [getManufacturerId]);

    const downloadLedgerReport = useCallback((batchId, fileName, manufacturerId, uploadId) => {
        setDownloadLedgerInProgress(true);
        setActiveUploadId(uploadId);
        const extension = "." + fileName.split('.').pop();
        const contentType = extension.toLowerCase() === ".csv" ? "text/csv" : "application/excel";
        handleDownloadLedgerHistory(batchId, manufacturerId, fileName, extension, contentType)
            .then(() => resetDownloadState(false,true))
            .catch(() => resetDownloadState(false,true));
    }, [getManufacturerId]);

    const downloadHistoric = useCallback((batchId, fileName, manufacturerId, uploadId) => {
        setDownloadHistoricInProgress(true);
        setActiveUploadId(uploadId);
        const extension = "." + fileName.split('.').pop();
        const contentType = extension.toLowerCase() === ".csv" ? "text/csv" : "application/excel";
        handleDownloadReport(batchId, manufacturerId, fileName, extension, contentType)
            .then(() => resetDownloadState(true))
            .catch(() => resetDownloadState(true));
    }, [getManufacturerId]);

    const resetDownloadState = useCallback((isHistoric, isLedger) => {
        if (isLedger) {
            setDownloadLedgerInProgress(false);
        }else if (isHistoric) {
            setDownloadHistoricInProgress(false);
        } else {
            setDownloadOriginalInProgress(false);
        }
        setActiveUploadId(null);
    }, []);

    const handleDialogClose = useCallback(() => {
        setDialogState(false);
        setDialogProgress(false);
        setDialogContent({ ...dialogContent, message: null });
        setError(false);
    }, [dialogContent]);

    const handleDelete = useCallback((uploadId, filename) => {
        setDialogContent({
            title: 'Delete existing data',
            body: deleteAlertContent(filename),
            actionType: 1,
            uploadId: uploadId,
            actionButtonTitle: 'Delete',
        });
        setDialogState(true);
    }, []);

    const handleRestore = useCallback((uploadId, filename) => {
        setDialogContent({
            title: 'Restore deleted data',
            body: restoreAlertContent(filename),
            actionType: 2,
            uploadId: uploadId,
            actionButtonTitle: 'Restore',
        });
        setDialogState(true);
    }, []);
    const deleteAlertContent = (filename) => (
        <div className={classes.dialogContentContainer}>
            <span className={classes.subheaderLarge}>
                Are you sure you want to delete the uploaded product serials data?
            </span>
            <br /> This action cannot be undone.
            <br /> Please review the following details before proceeding:
            <div className={classes.dialogFileInfo}>
                <p>File to be deleted: <span className={classes.dialogFileName}>{filename}</span> </p>
                <p>Shared with CER Serial Ledger:<span className={classes.dialogFileName}>Yes</span>  </p>
            </div>
            Please note that deleting this data may have consequences, including the loss of important information or potential disruptions to the CER Serial Ledger.<br />
            Please ensure that you have carefully reviewed the uploaded data and confirmed that it is indeed the correct data to delete.
        </div>
    );

    const restoreAlertContent = (filename) => (
        <div className={classes.dialogContentContainer}>
            <span className={classes.subheaderLarge}>
                Are you sure you want to restore the deleted product serials data?
            </span>
            <br /> This action cannot be undone.
            <br /> Please review the following details before proceeding: <br />
            <div className={classes.dialogFileInfo}>
                <p>File to be restored: <span className={classes.dialogFileName}>{filename}</span> </p>
            </div>
            <br />Please ensure that you have carefully reviewed the <strong>deleted data</strong> and confirmed that it is indeed the correct data to <strong>restore</strong>.
        </div>
    );
    const handleDialogAction = useCallback(() => {
        if (!dialogContent.uploadId) return;
        setDialogProgress(true);

        const actionHandler = dialogContent.actionType === 1 ? handleDeleteBatch : handleRestoreBatch;

        actionHandler(dialogContent.uploadId)
            .then(() => {
                refetch();
                handleDialogClose();
                onDone && onDone();
            })
            .catch((error) => {
                setDialogProgress(false);
                if (error?.status === false) {
                    setError(true);
                    setDialogContent({ ...dialogContent, message: error.message });
                    setTimeout(() => {}, 4000);
                }
            });
    }, [dialogContent, handleDialogClose]);

    return (
        <UploadHistoryContext.Provider
            value={{
               
               
                downloadOriginal,
                downloadHistoric,
                downloadLedgerReport,
                handleDelete,
                handleRestore,
                handleDialogAction,
                handleDialogClose,
                dialogContent,
                dialogProgress,
                isDownloadOriginalInProgress,
                isDownloadHistoricInProgress,
                isDownloadLedgerInProgress,
                activeUploadId,
                isAdmin,
                dialogState,
                hasError,
                restoreAlertContent,
                deleteAlertContent
            }}
        >
            {children}
        </UploadHistoryContext.Provider>
    );
};

export const useUploadHistory = () => useContext(UploadHistoryContext);
