import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, Tooltip, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useState, Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography, Badge } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import ReportIcon from '@material-ui/icons/AssessmentOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DownloadIcon from '@material-ui/icons/SystemUpdateAltSharp';
import { green500, orange500, red500 } from 'material-ui/styles/colors';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Slide

} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useUploadHistory } from './UploadHistoryContextProvider';
import _ from 'lodash';



const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        margin: '10px',
        width:'100%'
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold'

    },
    cardHeaderRoot: {
    },
    cardContentRoot: {
        paddingTop: '0px',
        paddingBottom: '5px'

    },
    cardActionRoot: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '1rem',
        padding: '8px 20px'
    },
    cardContent: {
    },
    button: {
        margin: '0px',
        minWidth: '32px',
        color: 'blue',
        background: 'none',
        border: 'none',
        textShadow: 'none',
        boxShadow: 'none',
        '&:hover': {
            color: orange500,
            margin: '0px',
            background: 'none',
            border: 'none',
            textShadow: 'none',
            boxShadow: 'none',
        },
        padding: "0px",
        fontSize: "10px",
        "& label": {
            fontSize: "10px",
            padding: "0px"
        }
    },
    buttonIcon: {
        marginRight: '3px'
    },

    pos: {
        marginBottom: 12,
    },
    MuiCardHeader: {
        '&.content': {
            backgroundColor: 'Red'
        }

    },
    avatar: {
        width: '15px',
        height: '15px',
        fontSize: 12,
        backgroundColor: green500,
        '&.restored': {
            backgroundColor: theme.palette.green500,
        },
        '&.deleted': {
            backgroundColor: red500
        },
        '&.resolved': {
            backgroundColor: orange500
        }
    },
    cardHeaderContent: {
        width: '80%',
    },
    heading: {
        fontSize: 12,
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        marginTop: 8,
        marginBottom: 0,
        wordWrap: 'break-word',
        maxWidth: '80%'
    },
    subheader: {
        fontSize: 14,
        color: theme.palette.grey[500],
        marginBottom: '0.875em',
    },

    subheader2: {
        color: theme.palette.grey[800],
        marginBottom: '0.875em',
        marginRight: 18,
        '&.restored': {
            color: theme.palette.green500,
        },
        '&.deleted': {
            color: red500
        },
        '&.resolved': {
            color: orange500
        }

    },
    statLabel: {
        fontSize: 11,
        color: theme.palette.grey[500],
        fontWeight: 500,
        fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        margin: 0
    },
    statValue: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 4,
        letterSpacing: '1px',
        '&.deleted': {
            color: red500
        },
        '&.resolved': {
            color: orange500
        }
    },
    statBoxAction: {
        justifyContent: 'flex-start',
        display: 'flex',
        marginTop: '10px',
        marginLeft: '10px'
    },
    statBoxIconLabel: {
        fontSize: 14
    },
    statBoxText: {
        justifyContent: 'flex-end',
        display: 'contents'
    },
    circularProgress: {
        margin: "0 10px"
    },
    downBtn: {
        padding: 0,
        marginLeft: "4px",
        "&:hover": {
            background: "none",
        }
    },

    badge: {
        marginLeft: '20px'
    },
    badgeContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'end',
        marginRight: '30px' //badge width 20px
    },
    actionBox: {

    },
    actionBoxChild: {
        flex: '1 1 auto',
        maxWidth: '48%'
    },
    divider: {
        marginTop: '8px',
        marginBottom:'5px'
    },
    statBlock: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '1rem',
        flex: '1 1 auto'
    },
    actionButtonBox: {
        display: 'flex',
        justifyContent: 'space-between', // Ensures proper alignment with space
        alignItems: 'center',
        flex: '1 1 auto',
        marginBottom: '5px'
    },
    statHeader: {
        fontSize: 12,
        minWidth: '132px'
    },
    actionButtonBoxContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        gap: '5px',
        marginRight:'10px'
    },
    buttonWrapper: {
        display: 'inline-block', // Prevent button layout distortion
        gap: '8px', // Add spacing between buttons
    }

}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const IsolatedUploadHistoryItem = props => {
    const classes = useStyles();
    const {
        handleDelete,
        handleRestore,
        downloadOriginal,
        downloadHistoric,
        downloadLedgerReport,
        isDownloadOriginalInProgress,
        isDownloadHistoricInProgress,
        isDownloadLedgerInProgress,
        activeUploadId,
        isAdmin,
        dialogState,
        handleDialogAction,
        handleDialogClose,
        dialogContent,
        dialogProgress,
        hasError
    } = useUploadHistory();



    const [anchorEl, setAnchorEl] = React.useState(null);
    const upload = props.upload;

    const open = Boolean(anchorEl);



    const handleMenuOpen = (event, id) => {

        setAnchorEl(event.currentTarget);
        //const addToState = { [id]: true }
        //setMenuActive({ addToState })
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const uploadType = upload.type === 2 ? "Resolved" : "Fresh";
    const fileState = upload.isDeleted ? "Deleted" : (!_.isNil(upload.restoredFrom) ? "Restored" : "Original");
    const avatorLegend = upload.type != null && upload.isDeleted ? "D" : (!_.isNil(upload.restoredFrom) ? "RC" : (upload.type === 2 ? "R" : "F"));
    const sizes = { xs: 12, sm: props.single ? 12 : 6, md: props.single ? 12 : 4 };
    const hideLedger = upload.totalRecords == upload.failed;
    const anythingInLedger = upload.ledgerHistory ? (upload.ledgerHistory.ledgerPassCount + upload.ledgerHistory.ledgerFailCount) : 0;
    

    return (

        <Grid item key={upload.uploadId} {...sizes}>
            <Card className={classes.root} {...sizes}>
                <CardHeader classes={{
                    root: classes.cardHeaderRoot,
                    content: classes.cardHeaderContent, // class name, e.g. `classes-nesting-label-x`
                }}

                    action={
                        <Fragment >
                            {isAdmin && upload.totalRecords !== upload.failed && (<IconButton aria-label="settings" onClick={(e) => { handleMenuOpen(e, "upload-" + upload.uploadId) }}>
                                <MoreVertIcon />
                            </IconButton>)
                            }
                            {isAdmin && upload.totalRecords !== upload.failed && (<Menu id={"upload-" + upload.uploadId} key={"upload-" + upload.uploadId}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={() => { handleMenuClose("upload-" + upload.uploadId) }}
                            >
                                {upload.totalRecords !== upload.failed && upload.isDeleted !== null && (
                                    <MenuItem key="restore" onClick={() => {
                                        handleMenuClose("upload-" + upload.uploadId);
                                        handleRestore(upload.uploadId, upload.sourceFileName);
                                    }}>
                                        Restore

                                    </MenuItem>
                                )}

                                {upload.totalRecords !== upload.failed && upload.isDeleted === null && (
                                    <MenuItem key="delete" onClick={() => {
                                        handleMenuClose("upload-" + upload.uploadId);
                                        handleDelete(upload.uploadId, upload.sourceFileName);
                                    }}>
                                        Delete
                                    </MenuItem>
                                )}

                            </Menu>)}
                        </Fragment>
                    }
                    title={
                        <h4 className={classes.heading}>{upload.sourceFileName} </h4>
                    }
                />
                <CardContent p={0} classes={{ root: classes.cardContentRoot }} >
                    <Typography>Id: <strong>{upload.uploadId}</strong></Typography>
                    <Typography>Date: <strong>{upload.uploadedOn}</strong></Typography>
                    <Typography>User: {upload.user.email}</Typography>
                    <div className={classes.badgeContainer}>
                        <Badge  className={classes.badge}
                            badgeContent={uploadType}
                            color={upload.type === 1 ? "primary" : "secondary"} />
                    </div>

                    <Typography variant="caption" className={`${classes.subheader2} ${upload.isDeleted ? "deleted" : (!_.isNil(upload.restoredFrom) ? "restored" : (upload.type === 2 ? "resolved" : ""))}`}>
                        {fileState}
                    </Typography>

                    <Divider className={classes.divider} p={1} light />

                </CardContent>
                <CardActions classes={{
                    root: classes.cardActionRoot
                }} p={0} disableSpacing>
                    <div className={classes.actionBoxChild}>
                        <div className={classes.actionButtonBox}>
                            <Typography className={classes.statHeader}>Upload Stats </Typography>
                            <div className={classes.actionButtonBoxContainer}>

                                <Button disabled={activeUploadId === upload.uploadId && isDownloadOriginalInProgress}

                                    color="secondary"
                                    className={classes.button}

                                    onClick={() => {
                                        downloadOriginal(upload.batchId, upload.sourceFileName, upload.manufacturerId, upload.uploadId);
                                    }}
                                >
                                    Original {isDownloadOriginalInProgress && activeUploadId === upload.uploadId && <CircularProgress className={classes.circularProgress} size={16} />}
                                </Button>
                                <Button
                                    disabled={activeUploadId === upload.uploadId && isDownloadHistoricInProgress}

                                    color="secondary"
                                    className={classes.button}

                                    onClick={() => {
                                        downloadHistoric(upload.batchId, upload.reportFileName ? upload.reportFileName : upload.sourceFileName, upload.manufacturerId, upload.uploadId);
                                    }}
                                >
                                    Report {isDownloadHistoricInProgress && activeUploadId === upload.uploadId && <CircularProgress className={classes.circularProgress} size={16} />}
                                </Button>
                            </div>
                        </div>
                        <div className={classes.statBlock}>
                            <Box className={classes.valueBox} p={0} flex={'auto'} >
                                <Typography className={classes.statLabel}>Total</Typography>
                                <Typography className={classes.statValue}>{upload.totalRecords}</Typography>
                            </Box>
                            <Box className={classes.valueBox} flex={'auto'} >
                                <Typography className={classes.statLabel}>{upload.isDeleted ? "Deleted" : "Saved"}</Typography>
                                <Typography className={classes.statValue} style={{ "color": "green" }}>
                                    {upload.success}
                                </Typography>

                            </Box>
                            <Box className={classes.valueBox} flex={'auto'}  >
                                <Typography className={classes.statLabel}>{upload.isDeleted ? "Undeleted" : "Failed"}</Typography>
                                <Typography className={classes.statValue} style={{ "color": "red" }}>
                                    {upload.failed}
                                </Typography>

                            </Box>
                        </div>
                    </div>
                    <Divider p={1}  orientation="vertical" light flexItem />

                    {!hideLedger && <div className={classes.actionBoxChild}>
                        <div className={classes.actionButtonBox}>
                            <Typography className={classes.statHeader}>
                                Ledger Stats
                                {anythingInLedger > 0 && upload.ledgerHistory?.ledgerDate && ` (${upload.ledgerHistory.ledgerDate})`}
                            </Typography>
                            <div className={classes.actionButtonBoxContainer}>
                                <Button
                                    disabled={activeUploadId === upload.uploadId && isDownloadLedgerInProgress}

                                    color="secondary"
                                    className={classes.button}

                                    onClick={() => {
                                        downloadLedgerReport(upload.batchId, "SerialLedgerReport.csv", upload.manufacturerId, upload.uploadId);
                                    }}
                                >
                                    {!isDownloadLedgerInProgress && activeUploadId !== upload.uploadId && "Report"}
                                    {isDownloadLedgerInProgress && activeUploadId === upload.uploadId && <CircularProgress className={classes.circularProgress} size={16} />}
                                </Button>

                            </div>
                        </div>

                        <div className={classes.statBlock}>
                            
                            <Box className={classes.valueBox} flex={'auto'} >
                                <Typography className={classes.statLabel}>Uploaded</Typography>
                                <Typography className={classes.statValue} style={{ "color": "green" }}>
                                    {upload.ledgerHistory && upload.ledgerHistory.ledgerPassCount}
                                </Typography>
                            </Box>
                            <Box className={classes.valueBox} flex={'auto'} >
                                <Typography className={classes.statLabel}>Failed</Typography>
                                <Typography className={classes.statValue} style={{ "color": "red" }}>
                                    {upload.ledgerHistory && upload.ledgerHistory.ledgerFailCount}
                                </Typography>
                            </Box>
                            <Box className={classes.valueBox} flex={'auto'} >
                                <Typography className={classes.statLabel}>Pending</Typography>
                                <Typography className={classes.statValue} style={{ "color": "orange" }}>
                                    {upload.ledgerHistory && upload.ledgerHistory.ledgerPendingCount}
                                </Typography>
                            </Box>
                        </div>
                    </div>}



                </CardActions>
            </Card>
            <Dialog
                open={dialogState}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{dialogContent.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText component="div" id="alert-dialog-slide-description" >
                        {dialogContent.body}
                        <div>
                            <span className={`${classes.message} ${hasError ? classes.errorMessage : ""}`}>{dialogContent.message}</span>
                        </div>
                    </DialogContentText>

                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Button onClick={handleDialogClose} className={classes.cancelButton} disabled={dialogProgress}>
                        Cancel
                    </Button>
                    <Button onClick={handleDialogAction} disabled={dialogProgress || hasError} color="primary">
                        {dialogContent.actionButtonTitle} {dialogProgress && <CircularProgress className={classes.circularProgress} size={16} />}
                    </Button>
                </DialogActions>
            </Dialog>

        </Grid>

    );
}

export default IsolatedUploadHistoryItem;
